import Vue from 'vue';
import VueRouter from 'vue-router'
import {getCookie} from 'tiny-cookie'
import { SmartStorage } from "smart-core-util";
//解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
        return originalPush.call(this, location, onResolve, onReject);
    return originalPush.call(this, location).catch((err) => err);
};
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
        return originalReplace.call(this, location, onResolve, onReject);
    return originalReplace.call(this, location).catch((err) => err);
};

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
        path: '/',
        redirect: '/Home',
        },
        {
            path: '/index',
            name: 'index',
            component: resolve => require(["@/pages/Home/Index.vue"], resolve),
        },
        {
            path: '/loading',
            name: 'loading',
            component: resolve => require(["@/pages/Home/Loading.vue"], resolve),
        },
        {
            path: '/Home', //首页
            name: 'Home',
            component: resolve => require(["@/pages/Home/Home.vue"], resolve),
            redirect: '/Home/EventList',
            children: [{
                path: '/Home/EventList', //会议列表
                name: 'EventList',
                component: resolve => require(["@/pages/Home/EventList.vue"], resolve)
            }, {
                path: '/Home/Message', //消息列表
                name: 'Message',
                component: resolve => require(["@/pages/Home/Message.vue"], resolve)
            }, {
                path: '/Home/Personal', //我的
                name: 'Personal',
                component: resolve => require(["@/pages/Home/Personal.vue"], resolve)
            }, {
                path: '/Home/More', //更多
                name: 'More',
                component: resolve => require(["@/pages/Home/More.vue"], resolve)
            }]
        },
        {
            path: '/CreateEvent', //会议基本信息--/--创建会议--/--编辑会议
            name: 'CreateEvent',
            component: resolve => require(["@/pages/CreateEvent.vue"], resolve)
        },
        {
            path: '/EventOrganizer', //选择参会人
            name: 'EventOrganizer',
            component: resolve => require(["@/pages/Select/EventOrganizer.vue"], resolve)
        },
        {
            path: '/POByMarker', //选择po操作人
            name: 'POByMarker',
            component: resolve => require(["@/pages/Select/POByMarker.vue"], resolve)
        },
        {
            path: '/Speaker', //选择讲者
            name: 'Speaker',
            component: resolve => require(["@/pages/Select/Speaker.vue"], resolve)
        },
        {
            path: '/Quorum', //人数
            name: 'Quorum',
            component: resolve => require(["@/pages/Select/Quorum.vue"], resolve)
        },
        {
            path: '/Hospital', //医院、科室
            name: 'Hospital',
            component: resolve => require(["@/pages/Select/Hospital.vue"], resolve)
        },
        {
            path: '/survey', //问卷
            name: 'survey',
            component: resolve => require(["@/components/survey/survey.vue"], resolve)
        },
        {
            path: '/CostCenter', //成本中心
            name: 'CostCenter',
            component: resolve => require(["@/pages/Select/CostCenter.vue"], resolve)
        },
        {
            path: '/Drafts', //草稿箱
            name: 'Drafts',
            component: resolve => require(["@/pages/Drafts.vue"], resolve)
        },
        {
            path: '/EventDetail', //会议详情页
            name: 'EventDetail',
            component: resolve => require(["@/pages/EventDetail/EventDetail.vue"], resolve)
        },
        {
            path: '/demandList', //其他品类需求列表（大巴、搭建、网络直播会议）
            name: 'DemandList',
            component: resolve => require(["@/pages/OtherCategory/DemandList.vue"], resolve)
        },
        {
            path: '/demandCreateAndEdit', //其他品类添加\编辑需求
            name: 'DemandCreateAndEdit',
            component: resolve => require(["@/pages/OtherCategory/DemandCreateAndEdit.vue"], resolve)
        },
        {
            path: '/ShareReport', //分摊报告
            name: 'ShareReport',
            component: resolve => require(["@/pages/EventDetail/ShareReport.vue"], resolve)
        },
        {
            path: '/MessageDetail', //消息详情页
            name: 'MessageDetail',
            component: resolve => require(["@/pages/MessageDetail.vue"], resolve)
        },
        {
            path: '/Progress', //进程
            name: 'Progress',
            component: resolve => require(["@/pages/Progress.vue"], resolve)
        },
        {
            path: '/RelevantPolicy', //相关政策
            name: 'RelevantPolicy',
            component: resolve => require(["@/pages/Personal/RelevantPolicy.vue"], resolve)
        },
        {
            path: '/AACPolicies', //活动和会议政策
            name: 'AACPolicies',
            component: resolve => require(["@/pages/Personal/AACPolicies.vue"], resolve)
        },
        {
            path: '/BudgetList', //预算信息
            name: 'BudgetList',
            component: resolve => require(["@/pages/Budget/BudgetList.vue"], resolve)
        },
        {
            path: '/AddBudgetItem', //添加预算项目
            name: 'AddBudgetItem',
            component: resolve => require(["@/pages/Budget/addBudgetItem.vue"], resolve)
        },
        {
            path: '/ServiceStaff', //上会服务人员
            name: 'ServiceStaff',
            component: resolve => require(["@/pages/Budget/ServiceStaff.vue"], resolve)
        },
        {
            path: '/StaffList', //会议执行--查看上会服务人员
            name: 'StaffList',
            component: resolve => require(["@/pages/Budget/StaffList.vue"], resolve)
        },
        {
            path: '/CostAllocation', //费用分摊
            name: 'CostAllocation',
            component: resolve => require(["@/pages/Cost/CostAllocation.vue"], resolve)
        },
        {
            path: '/AddContributor', //添加分摊人
            name: 'AddContributor',
            component: resolve => require(["@/pages/Cost/AddContributor.vue"], resolve)
        },
        {
            path: '/Calendar', //录入日程
            name: 'Calendar',
            component: resolve => require(["@/pages/Calendar/Calendar.vue"], resolve)
        },
        {
            path: '/AddCalendar', //添加日程
            name: 'AddCalendar',
            component: resolve => require(["@/pages/Calendar/AddCalendar.vue"], resolve)
        },
        {
            path: '/calendarHistory', //日程历史版本
            name: 'CalendarHistory',
            component: resolve => require(["@/pages/Calendar/CalendarHistory.vue"], resolve)
        },
        {
            path: '/calendarHistoryInfo', //日程历史版本详情
            name: 'CalendarHistoryInfo',
            component: resolve => require(["@/pages/Calendar/HistoryInfo.vue"], resolve)
        },
        {
            path: '/AddCalendarNew', //添加日程
            name: 'AddCalendarNew',
            component: resolve => require(["@/pages/Calendar/AddCalendarNew.vue"], resolve)
        },
        {
            path: '/AttendeeList', //参会人列表
            name: 'AttendeeList',
            component: resolve => require(["@/pages/Attendee/AttendeeList.vue"], resolve)
        },
        {
            path: '/AddAttendee', //选择参会人
            name: 'AddAttendee',
            component: resolve => require(["@/pages/Attendee/AddAttendee.vue"], resolve)
        },
        {
            path: '/SlectCommonAttendee',//选择常用参会人
            name: 'SlectCommonAttendee',
            component: resolve => require(["@/pages/Attendee/SlectCommonAttendee.vue"], resolve)
        },
        {
            path: '/addAttenderForm',//添加参会人表单
            name: 'addAttenderForm',
            component: resolve => require(["@/pages/Attendee/addAttenderForm.vue"], resolve)
        },
        {
            path: '/CheckinList', //签到首页
            name: 'CheckinList',
            component: resolve => require(["@/pages/Checkin/CheckinList.vue"], resolve)
        },
        {
            path: '/AddCheckin', //添加签到记录
            name: 'AddCheckin',
            component: resolve => require(["@/pages/Checkin/AddCheckin.vue"], resolve)
        },
        {
            path: '/CheckinStatistics', //  查看签到统计
            name: 'CheckinStatistics',
            component: resolve => require(["@/pages/Checkin/CheckinStatistics.vue"], resolve)
        },
        {
            path: '/HCPInvite', //HCP邀约
            name: 'HCPInvite',
            component: resolve => require(["@/pages/Invite/HCPInvite.vue"], resolve)
        },
        {
            path: '/SelectInviter', //选择邀约人员
            name: 'SelectInviter',
            component: resolve => require(["@/pages/Invite/SelectInviter.vue"], resolve)
        },
        {
            path: '/InviteWay', //选择邀约方式
            name: 'InviteWay',
            component: resolve => require(["@/pages/Invite/InviteWay.vue"], resolve)
        },
        {
            path: '/InviteTemplate', //邀约模板
            name: 'InviteTemplate',
            component: resolve => require(["@/pages/Invite/InviteTemplate.vue"], resolve)
        },
        {
            path: '/ExeFiles', //履行文件
            name: 'ExeFiles',
            component: resolve => require(["@/pages/ExeFiles/ExeFiles.vue"], resolve)
        },
        {
            path: '/budgetApproveFiles', //预算审批文件
            name: 'budgetApproveFiles',
            component: resolve => require(["@/pages/ExeFiles/budgetApproveFiles.vue"], resolve)
        },
        {
            path: '/CheckRecord', //签到记录
            name: 'CheckRecord',
            component: resolve => require(["@/pages/ExeFiles/checkRecord.vue"], resolve)
        },
        {
            path: '/LecturesDraft', //演讲文稿
            name: 'LecturesDraft',
            component: resolve => require(["@/pages/ExeFiles/LecturesDraft.vue"], resolve)
        },
        {
            path: '/AddLectures', //添加演讲文稿
            name: 'AddLectures',
            component: resolve => require(["@/pages/ExeFiles/AddLectures.vue"], resolve)
        },
        {
            path: '/SitePhotos', //上传现场照片
            name: 'SitePhotos',
            component: resolve => require(["@/pages/ExeFiles/SitePhotos.vue"], resolve)
        },
        {
            path: '/ContractAgreement', //患者组织合同及协议
            name: 'ContractAgreement',
            component: resolve => require(["@/pages/ExeFiles/ContractAgreement.vue"], resolve)
        },
        {
            path: '/APPXFiles', //APPX文件
            name: 'APPXFiles',
            component: resolve => require(["@/pages/ExeFiles/APPXFiles.vue"], resolve)
        },
        {
            path: '/AttendeesAuthority', //上会人员
            name: 'AttendeesAuthority',
            component: resolve => require(["@/pages/AttendeesAuthority/AttendeesAuthority.vue"], resolve)
        },
        {
            path: '/AddAttendeesAuthority', //添加上会人员
            name: 'AddAttendeesAuthority',
            component: resolve => require(["@/pages/AttendeesAuthority/AddAttendeesAuthority.vue"], resolve)
        },
        {
            path: '/SelectAttendeesAuthority', //选择上会人员
            name: 'SelectAttendeesAuthority',
            component: resolve => require(["@/pages/AttendeesAuthority/SelectAttendeesAuthority.vue"], resolve)
        },
        {
            path: '/AgencyEmployee', //代理提名
            name: 'AgencyEmployee',
            component: resolve => require(["@/pages/Agency/AgencyEmployee.vue"], resolve)
        },
        {
            path: '/AddAgency', //添加代理
            name: 'AddAgency',
            component: resolve => require(["@/pages/Agency/AddAgency.vue"], resolve)
        },
        {
            path: '/AgencyEvent', //需要代理提名的会议
            name: 'AgencyEvent',
            component: resolve => require(["@/pages/Agency/AgencyEvent.vue"], resolve)
        },
        {
            path: '/AgencyEventDetail', //需要代理提名的会议详情
            name: 'AgencyEventDetail',
            component: resolve => require(["@/pages/Agency/AgencyEventDetail.vue"], resolve)
        },
        {
            path: '/AgencyAttendee', //需要代理需要的参会人列表
            name: 'AgencyAttendee',
            component: resolve => require(["@/pages/Agency/AgencyAttendee.vue"], resolve)
        },
        {
            path: '/AddAgencyAttendee', //添加需要代理需要的参会人
            name: 'AddAgencyAttendee',
            component: resolve => require(["@/pages/Agency/AddAgencyAttendee.vue"], resolve)
        },
        {
            path: '/LicenseEmployee', //用车授权
            name: 'LicenseEmployee',
            component: resolve => require(["@/pages/CarLicense/LicenseEmployee.vue"], resolve)
        },
        {
            path: '/AddLicenseEmployee', //添加授权
            name: 'AddLicenseEmployee',
            component: resolve => require(["@/pages/CarLicense/AddLicenseEmployee.vue"], resolve)
        },
        {
            path: '/LicenseEvent', //需要用车授权的会议
            name: 'LicenseEvent',
            component: resolve => require(["@/pages/CarLicense/LicenseEvent.vue"], resolve)
        },
        {
            path: '/LicenseEventDetail', //需要用车授权的会议详情
            name: 'LicenseEventDetail',
            component: resolve => require(["@/pages/CarLicense/LicenseEventDetail.vue"], resolve)
        },
        {
            path: '/ReturnRecord', //退单记录
            name: 'ReturnRecord',
            component: resolve => require(["@/pages/ReturnRecord/ReturnRecord.vue"], resolve)
        },
        {
            path: '/ReturnRecordDetail', //退单详情
            name: 'ReturnRecordDetail',
            component: resolve => require(["@/pages/ReturnRecord/ReturnRecordDetail.vue"], resolve)
        },
        {
            path: '/APPScore', //APP评分
            name: 'APPScore',
            component: resolve => require(["@/pages/EventDetail/APPScore.vue"], resolve)
        },
        {
            path: '/fascoDetails', //APP评分
            name: 'fascoDetails',
            component: resolve => require(["@/pages/fasco/fascoList.vue"], resolve)
        },
        {
            path: '/addExpense', // 追加费用
            name: 'addExpense',
            component: resolve => require(["@/pages/addExpense/addExpense.vue"], resolve)
        },
        {
            path: '/addCatagory',  // 追加品类
            name: 'addCatagory',
            component: resolve => require(["@/pages/addExpense/addCatagory.vue"], resolve)
        },
        {
            path: '/addCatagoryDemand',  // 添加需求（品类）
            name: 'addCatagoryDemand',
            component: resolve => require(["@/pages/addExpense/addCatagoryDemand.vue"], resolve)
        },
        {
            path: '/addQuote', //追加报价列表
            name: 'addQuote',
            component: resolve => require(["@/pages/addQuote/addQuote.vue"], resolve)
        },
        {
            path: '/quoteList',// 追加报价
            name: '/quoteList',// 追加报价
            component: resolve => require(["@/pages/addQuote/quoteList.vue"], resolve)
        },
        {
            path: '/addQuoteDemand',// 追加报价
            name: '/addQuoteDemand',// 追加报价
            component: resolve => require(["@/pages/addQuote/addQuoteDemand.vue"], resolve)
        },
        {
            path: '/lectureDetail',// 讲课费明细
            name: 'LectureDetail',
            component: resolve => require(["@/pages/EventDetail/LectureDetail.vue"], resolve)
        },
        {
            path: '/speakerDetail',// 讲者明细
            name: 'SpeakerDetail',
            component: resolve => require(["@/pages/EventDetail/SpeakerDetail.vue"], resolve)
        },
        {
            path: '/transferOrderList',// 会议转单列表
            name: 'transferOrderList',
            component: resolve => require(["@/pages/transferOrder/transferOrderList.vue"], resolve)
        },
        {
            path: '/transferOrder',// 会议转单
            name: 'transferOrder',
            component: resolve => require(["@/pages/transferOrder/transferOrder.vue"], resolve)
        },
        {
            path: '/selectOrderReceivingPeople',// 选择会议接单人员
            name: 'selectOrderReceivingPeople',
            component: resolve => require(["@/pages/transferOrder/selectOrderReceivingPeople.vue"], resolve)
        },
        {
            path: '/selectTransferMice',// 选择转单会议
            name: 'selectTransferMice',
            component: resolve => require(["@/pages/transferOrder/selectTransferMice.vue"], resolve)
        },
        {
            path: '/transferOrderMiceList',// 已转单会议
            name: 'transferOrderMiceList',
            component: resolve => require(["@/pages/transferOrder/transferOrderMiceList.vue"], resolve)
        },
        {
            path: '/ticketingFIle',// 已转单会议
            name: 'ticketingFIle',
            component: resolve => require(["@/pages/ExeFiles/ticketingFIle.vue"], resolve)
        },
        {
            path: '/authorizationList',// 已授权列表
            name: 'authorizationList',
            component: resolve => require(["@/pages/authorizationManagement/authorizationList.vue"], resolve)
        },
        {
            path: '/addAuthorization',// 可授权列表
            name: 'addAuthorization',
            component: resolve => require(["@/pages/authorizationManagement/addAuthorization.vue"], resolve)
        },
        {
            path: '/SelectCity',// 餐车酒店查询
            name: 'SelectCity',
            component: resolve => require(["@/pages/Home/SelectCity.vue"], resolve)
        },
        {
            path: '/error',// 餐车酒店查询
            name: 'error',
            component: resolve => require(["@/pages/Home/error.vue"], resolve)
        }
    ]
})

router.beforeEach((to, from, next) => {
    if (!to.query.rnd) {
        const toQuery = JSON.parse(JSON.stringify(to.query));
        toQuery.rnd = new Date().getTime()
        next({
          path: to.path,
          query: toQuery
        })
  } else {
    next()
  }
});
export default router;
